import React, { useEffect, useState } from "react";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import { BookingApi } from "../../API/Apis/BookingApi";
import NotAuthorisedPage from "../notAuthorisedPage";
import ReservationCard from "../../components/Layouts/ReservationsCard/ReservationsCards";
import { t } from "i18next";

export default function MyReservedBookings() {
  const [showReservedBooking, setShowReservedBooking] = useState(true);
  const [openTab, setOpenTab] = useState(1);
  const [callFrom, setCallFrom] = useState("reservedBookingTab1");
  useEffect(() => {
    BookingApi.getAppSetting().then((e) => {
      setShowReservedBooking(e.reservedArea);
    });
  }, [showReservedBooking]);

  const handleOpenTab = (tab: number) => {
    setOpenTab(tab);
    if (tab == 1) {
      setCallFrom("reservedBookingTab1");
    } else if (tab == 2) {
      setCallFrom("reservedBookingTab2");
    } else if (tab == 3) {
      setCallFrom("reservedBookingTab3");
    }
  };
  return (
    <div>
      {showReservedBooking ? (
        <div className="flex">
          <BookingDetalisListSideNavigation />
          <div className="pt-12">
            <div className="container  mx-4">
              <div className="flex flex-col   ">
                <div className="flex justify-between space-x-2 mb-8  ">
                  <div className="pl-8">
                    <h1 className="font-medium text-[28px] text-[#232323] font-medium leading-snug">
                      {t("Reservation List")}
                    </h1>
                    <h4 className="text-[#838181] text-[20px]">
                      {t("Here is your next reserve bookings.")}
                    </h4>
                  </div>
                </div>

                <ul className="flex space-x-2 mb-12 absolute right-0">
                  <li>
                    <a
                      href="#"
                      onClick={() => handleOpenTab(1)}
                      variant="active"
                      className={` ${
                        openTab === 1 ? "bg-[#ffc800] text-[#000000]" : ""
                      } ${"inline-block px-4 py-2 w-40  h-70 text-gray-600  text-center rounded shadow"}`}
                    >
                      {t("Confirm")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => handleOpenTab(2)}
                      className={` ${
                        openTab === 2 ? "bg-[#ffc800]  text-[#000000]" : ""
                      } inline-block px-4 py-2 w-40 h-70 text-gray-600 text-center rounded shadow`}
                    >
                      {t("Pending")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => handleOpenTab(3)}
                      className={` ${
                        openTab === 3 ? "bg-[#ffc800]  text-[#000000]" : ""
                      } inline-block px-4 py-2 w-40  h-70  text-gray-600 text-center  rounded shadow`}
                    >
                      {t("Cancel")}
                    </a>
                  </li>
                </ul>
                <div className={`pl-8 ${openTab === 1 ? "block" : "hidden"}`}>
                  <ReservationCard callFrom={callFrom}></ReservationCard>
                </div>
                <div className={`pl-8 ${openTab === 2 ? "block" : "hidden"}`}>
                  <ReservationCard callFrom={callFrom}></ReservationCard>
                </div>
                <div className={`pl-8 ${openTab === 3 ? "block" : "hidden"}`}>
                  <ReservationCard callFrom={callFrom}></ReservationCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NotAuthorisedPage />
      )}
    </div>
  );
}
