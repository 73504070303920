import React, { useEffect, useState } from "react";
import { addDays, getDay } from "date-fns";

import AppButton from "../../UI/AppButtons";
import AppCard from "../../UI/AppCards";
import { AppInputSwitcher } from "../AppInputSwitcher/AppInputSwitcher";
import { AppRadioButton } from "../../UI/AppRadioButtons/AppRadioButtons";
import { AppStandardMultiSelectCheckBox } from "../../UI/AppMultipleSelection/AppMultiSelection";
import BookingApi from "../../../API/Apis/BookingApi";
import { BookingDetails } from "../../../@types/Booking/bookingDetails";
import { BookingFormProps } from "./BookingForm.props";
import BookingGetters from "../../../store/onlineBooking/getters";
import { Location } from "../../../@types/DTO/Locations";
import SearchIcon from "@mui/icons-material/Search";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import TrainIcon from "@mui/icons-material/Train";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { setEndTime, setTime } from "../../../helpers/dateManipulators";
import { useTranslation } from "react-i18next";
import { BookingSourceResponse } from "../../../@types/DTO/BookingSource";
import { t } from "i18next";
import { MacroClassGroup } from "../../../@types/DTO/MacroClass";
import { useSearchParams } from "react-router-dom";
import { BookingStateRedux } from "../../../store/onlineBooking/states";
import { RemoveRedEye } from "@mui/icons-material";

function getEnabledTimeSlots(
  locationArray: Location[],
  locationName: string,
  date: Date | null
) {
  return locationName && date
    ? locationArray
        .filter(
          (e) => e.locationName.toLowerCase() === locationName.toLowerCase()
        )[0]
        ?.openings.filter((e) => {
          return e.dayOfTheWeek % 7 == getDay(date);
        })
        .map((e) => {
          return {
            start: setTime(e.startTime, date),
            end: setEndTime(e.endTime, date),
          };
        })
    : [];
}
function BookingForm({
  onSubmit = () => {},
  className = "",
}: BookingFormProps): JSX.Element {
  const { t: translate } = useTranslation();
  const reduxBookingDetails: BookingDetails =
    BookingGetters.getBookingDetails();
  const isEmbedded = BookingGetters.getIsEmbedded();
  const AppSettings = BookingGetters.getAppSettings();
  const maxRentalAllowedDay = AppSettings?.maxRentalDurationInDays || 30;
  const [minRentalAllowedDay] = useState<number>(AppSettings?.minRentDays || 1);
  const [reservationSource, setReservationSource] = useState<string>(
    reduxBookingDetails.pickUpLocationDetails.name
      ? reduxBookingDetails.pickUpLocationDetails.name
      : ""
  );
  const [macroGroupOptions, setMacroGroupOptions] = useState<MacroClassGroup[]>(
    []
  );
  const [selectedMacroGroup, setSelectedMacroGroup] = useState<string>("");
  const [reservationDestination, setReservationDestination] = useState<string>(
    reduxBookingDetails.dropOffLocationDetails.name
      ? reduxBookingDetails.dropOffLocationDetails.name
      : ""
  );
  const [reservationStartDate, setStartDate] = useState<Date | null>(
    reduxBookingDetails.pickUpDateAndTime
      ? new Date(reduxBookingDetails.pickUpDateAndTime)
      : null
  );
  const [reservationEndDate, setEndDate] = useState<Date | null>(
    reduxBookingDetails.dropOffDateAndTime
      ? new Date(reduxBookingDetails.dropOffDateAndTime)
      : null
  );
  const [reservationPlatform, setReservationPlatform] = useState<string>(
    reduxBookingDetails.reservationSource
      ? reduxBookingDetails.reservationSource.name
      : ""
  );
  const [isDroppingAtSameLocation, setIsDroppingAtSameLocation] =
    useState<string>(translate("drop at same location"));

  const [reservationSourceOptions, setReservationSourceOptions] = useState<
    BookingSourceResponse[]
  >([]);
  const [isYoungDriverAge, setIsYoungDriverAge] = useState<string>(
    reduxBookingDetails.isYoungDriverAge
      ? reduxBookingDetails.isYoungDriverAge
      : "false"
  );
  const [isSeniorDriverAge, setIsSeniorDriverAge] = useState<string>(
    reduxBookingDetails.isSeniorDriverAge
      ? reduxBookingDetails.isSeniorDriverAge
      : "false"
  );
  const [isNoFeeAge, setIsNoFeeAge] = useState<string>(
    reduxBookingDetails.noFeeAge ? reduxBookingDetails.noFeeAge : "false"
  );
  const [reservationSourceEnabled, setReservationSourceEnabled] =
    useState(false);

  const [reservationSourceForUser, setReservationSourceForUser] = useState([]);
  useEffect(() => {
    const userId: any = localStorage.getItem("userID");
    if (userId != null && userId != "null" && userId != "") {
      setReservationSourceEnabled(true);
      BookingApi.getReservationSourceForOnlineUser(userId).then((e) => {
        setReservationSourceForUser(e.data.reservationSourceList);
      });
    }
  }, []);
  const [isAgeLimitSelected, setIsAgeLimitSelected] = useState<string>(
    reduxBookingDetails.isAgeLimitSelected
  );

  function requestParentResize() {
    const iframeHeight = document.body.scrollHeight;
    window.parent.postMessage(
      {
        event_id: "iframeHeight",
        data: {
          iframeHeight: iframeHeight + 100,
        },
      },
      "*"
    );
  }

  useEffect(() => {
    if (AppSettings?.showInIframe) {
      requestParentResize();
    }
  }, []);

  useEffect(() => {
    if (isAgeLimitSelected === "Age between 19-21") {
      setIsYoungDriverAge("true");
      setIsSeniorDriverAge("false");
      setIsNoFeeAge("false");
    } else if (isAgeLimitSelected === "Age between 22-24") {
      setIsYoungDriverAge("false");
      setIsSeniorDriverAge("true");
      setIsNoFeeAge("false");
    } else {
      setIsYoungDriverAge("false");
      setIsSeniorDriverAge("false");
      setIsNoFeeAge("true");
    }
  }, [isAgeLimitSelected]);
  const [showCoupan, setshowCoupan] = useState<boolean>(
    !!AppSettings?.showWebCodeInput
  );

  const [agreementCoupon, setagreementCoupon] = useState<string>(
    reduxBookingDetails.agreementCoupon
      ? reduxBookingDetails.agreementCoupon
      : ""
  );
  const [discountValueWithoutVat, setDiscountValueWithoutVat] = useState<any>(
    reduxBookingDetails.discountValueWithoutVat
      ? reduxBookingDetails.discountValueWithoutVat
      : "0"
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const [locationDetail, setLocationDetail] = useState<Location[]>([]);
  const [locationDropOffDetail, setLocationDropOffDetail] = useState<
    Location[]
  >([]);
  useEffect(() => {
    BookingApi.getReservationLocations().then((data: Record<string, any>) => {
      const sortByLocationName = (a, b) => {
        return a.locationName.localeCompare(b.locationName);
      };
      const sortedLocation = data.data.sort(sortByLocationName);
      const sortedPickUpLocation = sortedLocation.filter(
        (e) => e.locationType == 3 || e.locationType == 1
      );
      setLocationDetail(sortedPickUpLocation || []);
      const sortedDropOffUpLocation = sortedLocation.filter(
        (e) => e.locationType == 3 || e.locationType == 2
      );
      setLocationDropOffDetail(sortedDropOffUpLocation || []);
    });
    BookingApi.getAllMacroGroups().then((e) => {
      setMacroGroupOptions(e);
    });
    BookingApi.getAllReservationSources().then((data) => {
      setReservationSourceOptions(data);
    });
  }, []);

  return (
    <main className={`h-full flex  justify-center box-border ${className}`}>
      <form
        className={"w-full"}
        onSubmit={(e) => {
          e.preventDefault();
          const bookingDetailsForLinkDirection: BookingDetails = {
            dropOffDateAndTime: reservationEndDate?.getTime() || Date.now(),
            dropOffLocationDetails: {
              name: reservationSource,
              code: locationDetail.filter(
                (e) => e.locationName === reservationDestination
              )[0].locationCode,
            },
            pickUpLocationDetails: {
              name: reservationDestination,
              code: locationDetail.filter(
                (e) => e.locationName === reservationSource
              )[0].locationCode,
            },
            pickUpDateAndTime: reservationStartDate?.getTime() || Date.now(),
            reservationSource: {
              name: reservationPlatform,
              code:
                reservationSourceOptions.find(
                  (e) => e["Ragione Sociale"] === reservationPlatform
                )?.Codice || "",
            },
            agreementCoupon: agreementCoupon,
            discountValueWithoutVat: discountValueWithoutVat,
            macroGroupName: selectedMacroGroup,
            showPics: true,
            showOptionalImage: true,
            showVehicleParameter: true,
            showVehicleExtraImage: true,
            showBookingDiscount: true,
            isYoungDriverAge: isYoungDriverAge,
            isSeniorDriverAge: isSeniorDriverAge,
            noFeeAge: isNoFeeAge,
            isAgeLimitSelected: isAgeLimitSelected,
          };
          // setSearchParams(JSON.stringify(bookingDetailsForLinkDirection));
          onSubmit(bookingDetailsForLinkDirection);
        }}
      >
        <AppCard className="flex flex-col bg-white w-full box-border justify-center items-center p-4 gap-4 py-8">
          <div className="flex xl:flex-row gap-2 flex-col w-full">
            <AppInputSwitcher
              required={true}
              className={"w-full"}
              value={reservationSource}
              label={translate("pick up location")}
              key={"pickUpLocation"}
              options={locationDetail?.map((e: Location) => {
                let icon = <LocationCityIcon color="bookingButtonColor" />;
                if (e.isAirport) {
                  icon = <FlightTakeoffIcon htmlColor="bookingButtonColor" />;
                } else if (e.isRailway) {
                  icon = <TrainIcon htmlColor="bookingButtonColor" />;
                }
                return {
                  label: e.locationName,
                  value: e.locationName,
                  icon,
                };
              })}
              type="select"
              variant="with-search"
              onchange={(e) => {
                if (isDroppingAtSameLocation.length) {
                  setReservationDestination(e);
                }
                setReservationSource(e);
                setStartDate(null);
                setEndDate(null);
              }}
            />
            {!isDroppingAtSameLocation.length && (
              <AppInputSwitcher
                required={true}
                value={reservationDestination}
                label={translate("drop off location")}
                key={"dropOffLocation"}
                options={locationDropOffDetail.map((e) => e.locationName)}
                type="select"
                variant="with-search"
                onchange={(e) => {
                  setReservationDestination(e);
                  setEndDate(null);
                }}
              />
            )}
          </div>
          <AppInputSwitcher
            sx={{
              svg: { color: "#ffc800" },
              input: { color: "#ffc800" },
              label: { color: "#ffc800" },
              backgroundColor: "#ffc800",
            }}
            disableOnDay={
              reservationSource && locationDetail.length
                ? Object.keys(
                    locationDetail
                      .filter(
                        (e) =>
                          e.locationName.toLowerCase() ===
                          reservationSource.toLowerCase()
                      )[0]
                      ?.openings?.map((e) => {
                        return e.dayOfTheWeek % 7;
                      })
                      // .filter((e) => {
                      //   return (
                      //     (AppSettings?.noSabatoDomenica && e == 1) ||
                      //     e == 2 ||
                      //     e == 3 ||
                      //     e == 4 ||
                      //     e == 5
                      //   );
                      // })
                      .reduce(
                        (e, v) => {
                          delete e[v];
                          return e;
                        },
                        {
                          1: true,
                          2: true,
                          3: true,
                          4: true,
                          5: true,
                          6: true,
                          7: true,
                        }
                      )
                  ).map((e) => parseInt(e))
                : []
            }
            required={true}
            className="w-full grow md:min-w-72"
            label={translate("reservation dates")}
            key={"reservationDates"}
            options={[]}
            startDate={reservationStartDate}
            endDate={reservationEndDate}
            minDate={addDays(new Date(), minRentalAllowedDay)}
            maxDate={
              reservationStartDate
                ? addDays(
                    new Date(reservationStartDate?.toString()),
                    maxRentalAllowedDay
                  )
                : addDays(new Date(), 365)
            }
            type="date"
            variant="range"
            onchange={(selectedDateRange) => {
              if (selectedDateRange[0]) {
                setStartDate(
                  setTime(
                    locationDetail
                      .filter((e) => e.locationName == reservationSource)[0]
                      ?.openings.filter(
                        (e) =>
                          getDay(selectedDateRange[0]) === e.dayOfTheWeek % 7
                      )[0].startTime || "",
                    selectedDateRange[0]
                  )
                );
              } else {
                setStartDate(null);
              }
              if (selectedDateRange[1]) {
                setEndDate(
                  setTime(
                    locationDetail
                      .filter(
                        (e) => e.locationName == reservationDestination
                      )[0]
                      ?.openings.filter(
                        (e) =>
                          getDay(selectedDateRange[1]) === e.dayOfTheWeek % 7
                      )[0].startTime || "",
                    selectedDateRange[1]
                  )
                );
              } else {
                setEndDate(null);
              }
            }}
          />
          <AppInputSwitcher
            required={true}
            disabled={!reservationStartDate}
            className="w-full  min-w-[10rem]"
            label={translate("pick up time")}
            key={"pickUpTime"}
            enableTimesInRange={getEnabledTimeSlots(
              locationDetail,
              reservationSource,
              reservationStartDate
            )}
            value={reservationStartDate}
            options={[]}
            type="time"
            variant="slot"
            onchange={(e) => {
              setStartDate(e);
            }}
          />
          <AppInputSwitcher
            enableTimesInRange={getEnabledTimeSlots(
              locationDetail,
              reservationDestination,
              reservationEndDate
            )}
            disabled={!reservationEndDate}
            required={true}
            className="min-w-[10rem]"
            label={translate("drop off time")}
            key={"dropOffTime"}
            options={[]}
            value={reservationEndDate}
            type="time"
            variant="slot"
            onchange={(e) => {
              setEndDate(e);
            }}
          />
          <>
            {AppSettings?.showMacroGroupSelection && (
              <AppInputSwitcher
                required={false}
                className={"w-full min-w-[12rem]"}
                type="select"
                variant="with-search"
                label={translate("Macro Group")}
                options={macroGroupOptions?.map((e) => e.label) || []}
                value={selectedMacroGroup}
                onchange={(e) => {
                  setSelectedMacroGroup(e);
                }}
              />
            )}
          </>
          <>
            <AppInputSwitcher
              required={false}
              autoComplete="off"
              className="min-w-[10rem]"
              label={translate("Voucher Code")}
              key={"Voucher Code"}
              placeholder={translate("Voucher Code")}
              options={[]}
              value={agreementCoupon}
              type="text"
              variant="slot"
              onchange={(e) => {
                setagreementCoupon(e);
              }}
            />
          </>
          <AppButton
            color={"bookingButtonColor"}
            type={"submit"}
            endIcon={<SearchIcon />}
            className="h-14 w-full "
          >
            <p className={`w-full text-center`}>{translate("Search")}</p>
          </AppButton>
          {/* </div>*/}
          <div className="flex xl:flex-row gap-2 w-full flex-wrap">
            {AppSettings?.locationReturnEnable && (
              <div className="min-w-fit max-w-fit">
                <AppStandardMultiSelectCheckBox
                  value={[isDroppingAtSameLocation]}
                  onChange={(e) => {
                    setIsDroppingAtSameLocation(e[0] || "");
                    if (e.length) {
                      setReservationDestination(reservationSource);
                    }
                  }}
                  options={[translate("drop at same location")]}
                />
              </div>
            )}
            {reservationSourceEnabled === true &&
            reservationSourceForUser.length > 0 ? (
              <div className="flex" style={{ width: "400px" }}>
                <AppInputSwitcher
                  required={true}
                  className="w-full min-w-[12rem]"
                  label={translate("reservation source")}
                  key={"reservationPlatform"}
                  options={reservationSourceForUser?.map(
                    (e) => e["description"]
                  )}
                  type="select"
                  variant="with-search"
                  onchange={(e) => {
                    setReservationPlatform(e);
                  }}
                  value={reservationPlatform}
                />
              </div>
            ) : (
              AppSettings?.showReservationGroupSelection && (
                <div className="flex" style={{ width: "400px" }}>
                  <AppInputSwitcher
                    required={true}
                    className="w-full min-w-[12rem]"
                    label={translate("reservation source")}
                    key={"reservationPlatform"}
                    options={reservationSourceOptions?.map(
                      (e) => e["Ragione Sociale"]
                    )}
                    value={reservationPlatform}
                    type="select"
                    variant="with-search"
                    onchange={(e) => {
                      setReservationPlatform(e);
                    }}
                  />
                </div>
              )
            )}
            <div>
              <AppRadioButton
                required={true}
                value={isAgeLimitSelected}
                onChange={(e) => {
                  setIsAgeLimitSelected(e);
                }}
                options={[
                  {
                    label: translate("Age between 19-21"),
                    value: "Age between 19-21",
                  },
                  {
                    label: translate("Age between 22-24"),
                    value: "Age between 22-24",
                  },
                  {
                    label: translate("Age 25+"),
                    value: "Age 25+",
                  },
                ]}
              />
            </div>
          </div>
        </AppCard>
      </form>
    </main>
  );
}
export default BookingForm;
