import {
  AppAutoCompleteProps,
  AppSelectProps,
} from "../../UI/AppSelectOptions/AppSelect.props";
import AppDateAndTimePicker, {
  DateRangePicker,
} from "../../UI/AppDatePickers/AppDatePicker";
import {
  AppTimePickerMaterial,
  AppTimePickerStandard,
} from "../../UI/AppTimeSelect/AppTimeSelect";
import React, { ForwardedRef, forwardRef } from "react";

import AppDatePicker from "../../UI/AppDatePickers";
import AppDropDownSelect from "../../UI/AppSelectOptions";
import { AppDropDownWithSearch } from "../../UI/AppSelectOptions/AppSelect";
import { AppInputSwitchProps } from "./AppInputSwitcher.props";
import { AppMobileNumberProps } from "../../UI/AppMobileNumberInput/AppMobileNumberInput.props";
import { AppRadioButton } from "../../UI/AppRadioButtons/AppRadioButtons";
import { AppRadioButtonProps } from "../../UI/AppRadioButtons/AppRadioButtons.props";
import MaterialInput from "../../UI/AppTextField";
import { MaterialInputProps } from "../../UI/AppTextField/AppTextField.props";
import { MobileNumberInputFieldWithFlag } from "../../UI/AppMobileNumberInput";
import { StandardDatePickerProps } from "../../UI/AppDatePickers/AppPickers.props";

export const AppInputSwitcher = forwardRef(function AppInputSwitcher(
  {
    type = "text",
    variant = "standard",
    onchange = () => {},
    loading = false,
    loadingText = "",
    ...otherProps
  }: AppInputSwitchProps,
  ref: ForwardedRef<any>
) {
  const ALL_PROPS: AppInputSwitchProps = {
    type,
    variant,
    onChange: (e) => {
      onchange(e);
    },
    ...otherProps,
  };
  switch (type) {
    case "space": {
      return <div className={"w-full h-full"}></div>;
    }
    case "mobile": {
      return (
        <MobileNumberInputFieldWithFlag
          {...({
            ...ALL_PROPS,
            className: ALL_PROPS.className,
          } as AppMobileNumberProps)}
          onChange={(e) => {
            onchange(e);
          }}
          ref={ref}
        />
      );
    }
    case "text" || "email" || "number" || "password": {
      if (variant === "material") {
        return (
          <MaterialInput
            variant={"material"}
            {...({
              ...ALL_PROPS,
              onChange: (e) => {
                onchange(e);
              },
            } as MaterialInputProps)}
            type={type}
            ref={ref}
          />
        );
      }
      return (
        <MaterialInput
          variant={"standard"}
          {...({
            ...otherProps,
            onChange: (e) => {
              onchange(e);
            },
          } as MaterialInputProps)}
          type={type}
          onChange={(e) => {
            onchange(e);
          }}
          ref={ref}
        />
      );
    }
    case "select": {
      if (variant === "with-search") {
        return (
          <AppDropDownWithSearch
            {...({
              ...ALL_PROPS,
              disabled: ALL_PROPS.readOnly,
              loading,
              loadingText,
              variant: "material",
            } as AppAutoCompleteProps)}
            onChange={(e) => {
              onchange(e);
            }}
            ref={ref}
          />
        );
      }
      if (variant === "material") {
        return (
          <AppDropDownWithSearch
            {...({
              ...ALL_PROPS,
              disabled: ALL_PROPS.readOnly,
              loading,
              loadingText,
              variant: "material",
            } as AppAutoCompleteProps)}
            onChange={(e) => {
              onchange(e);
            }}
            ref={ref}
          />
        );
      }
      return (
        <AppDropDownSelect
          {...(ALL_PROPS as AppSelectProps)}
          ref={ref}
          onChange={(e) => {
            onchange(e);
          }}
        />
      );
    }
    case "radio": {
      return (
        <AppRadioButton
          {...({ ...ALL_PROPS, name: ALL_PROPS.key } as AppRadioButtonProps)}
          ref={ref}
          name={ALL_PROPS.key}
          onChange={(e) => {
            onchange(e);
          }}
        />
      );
    }
    case "date": {
      if (variant === "range") {
        return (
          <DateRangePicker
            {...({
              ...ALL_PROPS,
              onChange: (e) => {
                onchange(e);
              },
            } as StandardDatePickerProps)}
            startDate={ALL_PROPS.startDate || null}
            endDate={ALL_PROPS.endDate || null}
            ref={ref}
          />
        );
      }
      return (
        <AppDatePicker
          {...({
            ...otherProps,
            onChange: (e) => {
              onchange(e);
            },
          } as StandardDatePickerProps)}
          ref={ref}
          sx={{
            svg: { color: "#ffc800" },
            input: { color: "#ffc800" },
            label: { color: "#ffc800" },
            backgroundColor: "#ffc800",
          }}
        />
      );
    }
    case "date-time": {
      return (
        <AppDateAndTimePicker
          {...({
            ...otherProps,
            onChange: (e) => {
              onchange(e);
            },
          } as StandardDatePickerProps)}
          ref={ref}
        />
      );
    }
    case "time": {
      if (variant == "slot") {
        return (
          <AppTimePickerStandard
            {...({
              ...otherProps,
              onChange: (e) => {
                // console.log(e);

                onchange(e);
              },
            } as StandardDatePickerProps)}
            ref={ref}
          />
        );
      }
      return (
        <AppTimePickerMaterial
          {...({
            ...otherProps,
            onChange: (e) => {
              onchange(e);
            },
          } as StandardDatePickerProps)}
          ref={ref}
        />
      );
    }
  }
  return (
    <MaterialInput
      {...({
        ...otherProps,
        onChange: (e) => {
          onchange(e);
        },
      } as MaterialInputProps)}
      type={type}
      ref={ref}
    />
  );
});
