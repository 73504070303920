import "@mui/material/styles/createPalette";

import {
  PaletteOptions,
  SimplePaletteColorOptions,
  ThemeOptions,
  createTheme,
} from "@mui/material/styles";

import { MuiTextFieldVariantsOverridden } from "../modules/mui/TextFields";
import { Theme } from "@mui/system";

export interface ExtraColors {
  white: true;
  blue: true;
  inputFieldBorder: true;
  gray: true;
  red: true;
  darkRed: true;
  green: true;
  "success-2": true;
  "primary-2": true;
  "success-3": true;
  "primary-3": true;
  clear: true;
  bookingButtonColor: true;
  webCheckInSubmitButton: true;
  webCheckInNextButton: true;
  webCheckInPrevButton: true;
  addDriverButton: true;
  addInsuranceButton: true;
  addExtrasButton: true;
  rentalTermsAgreeButton: true;
  confirmBookingButton: true;
  chooseVehiclePayNowButton: true;
}
declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    addDriverButton: { main: "#1976d2"; contrastText: "#ffffff" };
    // addExtrasButton: { main: "#3c63ae" };
    // addInsuranceButton: { main: "#3c63ae" };
    addExtrasButton: { main: "#2267ae" };
    addInsuranceButton: { main: "#2267ae" };
    //bookingButtonColor: { main: "#3c63ae"; contrastText: "#FFFFFF" }; For Rental Plus
    // bookingButtonColor: { main: "#2267ae"; contrastText: "#FFFFFF" }; For autoone
    bookingButtonColor: { main: "#ffc800"; contrastText: "#000000" };
    confirmBookingButton: { main: "rgb(46, 125, 50)"; contrastText: "#ffffff" };
    rentalTermsAgreeButton: { main: "green"; contrastText: "#ffffff" };
    webCheckInNextButton: { main: "rgb(46, 125, 50)"; contrastText: "#ffffff" };
    webCheckInPrevButton: { main: "#be1a1a" };
    webCheckInSubmitButton: { main: "#2267ae" };
    chooseVehiclePayNowButton: { main: "#2267ae"; contrastText: "#ffffff" };
    // webCheckInSubmitButton: { main: "#3c63ae" };
    // chooseVehiclePayNowButton: { main: "#3c63ae"; contrastText: "#ffffff" }; // For Rental plus
    white: {
      main: "#fffff";
    };
    blue: {
      main: "#2563eb";
    };
    inputFieldBorder: {
      main: "#e6e6e6";
    };
    gray: {
      main: "#ECECEC";
    };
    red: {
      main: "#ff1717";
    };
    green: {
      main: "#1a7a67";
    };
    darkRed: {
      main: "#be1a1a";
    };
    "success-2": {
      main: "#33B627";
    };
    "primary-2": {
      main: "#F9CA01";
    };
    "success-3": {
      main: "#F7A200";
    };
    "primary-3": {
      main: "#004188";
    };
    clear: {
      main: "transparent";
    };
  }
}
declare module "@mui/material/styles" {
  interface PaletteOptions {
    white: SimplePaletteColorOptions;
    blue: SimplePaletteColorOptions;
    inputFieldBorder: SimplePaletteColorOptions;
    gray: SimplePaletteColorOptions;
    red: SimplePaletteColorOptions;
    darkRed: SimplePaletteColorOptions;
    green: SimplePaletteColorOptions;
    "success-2": SimplePaletteColorOptions;
    "primary-2": SimplePaletteColorOptions;
    "success-3": SimplePaletteColorOptions;
    "primary-3": SimplePaletteColorOptions;
    clear: SimplePaletteColorOptions;
    bookingButtonColor: SimplePaletteColorOptions;
    webCheckInSubmitButton: SimplePaletteColorOptions;
    webCheckInNextButton: SimplePaletteColorOptions;
    webCheckInPrevButton: SimplePaletteColorOptions;
    addDriverButton: SimplePaletteColorOptions;
    addInsuranceButton: SimplePaletteColorOptions;
    addExtrasButton: SimplePaletteColorOptions;
    rentalTermsAgreeButton: SimplePaletteColorOptions;
    confirmBookingButton: SimplePaletteColorOptions;
    chooseVehiclePayNowButton: SimplePaletteColorOptions;
  }
}

const palette: PaletteOptions = {
  addDriverButton: { main: "#1976d2", contrastText: "#ffffff" },
  // addExtrasButton: { main: "#F9CA01" },
  // addInsuranceButton: { main: "#F9CA01" },
  // addExtrasButton: { main: "#2267ae", contrastText: "#ffffff" }, //For autoone
  addExtrasButton: { main: "#202020", contrastText: "#ffffff" },
  addInsuranceButton: { main: "#202020", contrastText: "#ffffff" }, // For autoone
  // bookingButtonColor: { main: "#3c63ae", contrastText: "#ffffff" }, // For Rental Plus
  bookingButtonColor: { main: "#ffc800", contrastText: "#000000" }, // For autoone
  // bookingButtonColor: { main: "#F9CA01", contrastText: "#000000" }, //For myrent
  confirmBookingButton: { main: "rgb(46, 125, 50)", contrastText: "#ffffff" },
  rentalTermsAgreeButton: { main: "green", contrastText: "#ffffff" },
  // webCheckInNextButton: { main: "rgb(46, 125, 50)", contrastText: "#ffffff" },
  // webCheckInNextButton: { main: "#f9CA01", contrastText: "#000000" }, // For myrent
  webCheckInNextButton: { main: "#3c63ae", contrastText: "#000000" },
  webCheckInPrevButton: { main: "#be1a1a" },
  // webCheckInSubmitButton: { main: "#3c63ae" },
  // chooseVehiclePayNowButton: { main: "#3c63ae", contrastText: "#ffffff" }, // For Rental Plus
  // webCheckInSubmitButton: { main: "#2267ae" },
  chooseVehiclePayNowButton: { main: "#004188", contrastText: "#ffffff" }, // For autoone
  webCheckInSubmitButton: { main: "#f9ca01" },
  // chooseVehiclePayNowButton: { main: "#f9ca01", contrastText: "#ffffff" }, For myrent
  white: {
    main: "#fffff",
  },
  // primary: {
  //   main: "#f9ca01",
  // }, // For myrent all colors

  primary: {
    main: "#ffc800",
  },
  felirent: {
    main: "#17A1B7",
  },
  blue: {
    main: "#2563eb",
  },
  inputFieldBorder: {
    main: "#e6e6e6",
  },
  gray: {
    main: "#7a7c80",
  },
  red: {
    main: "#ff1717",
  },
  green: {
    main: "#1a7a67",
  },
  darkRed: {
    main: "#be1a1a",
  },
  "success-2": {
    main: "#33B627",
  },
  "primary-2": {
    main: "#F9CA01",
  },
  "success-3": {
    main: "#F7A200",
  },
  "primary-3": {
    main: "#004188",
  },
  clear: {
    main: "transparent",
  },
};
const customTheme: ThemeOptions = {
  components: {
    MuiTextField: {
      variants: MuiTextFieldVariantsOverridden,
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: "red",
        },
        root: {
          zIndex: "auto",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "red",
        },
        root: {
          zIndex: "auto",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "black",
        },
      },
    },
  },
  palette,
};
const theme: Theme = createTheme(customTheme);
export default theme;
